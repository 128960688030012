import { useForm, Controller } from "react-hook-form";
import React from "react";
import { useState, useEffect } from "react";

import ReactDOM from "react-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";

import api from "../api/api";

const NewActivity = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [value, onChange] = useState("10:00");
  const {
    control,
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // Submit Handling
  const onSubmit = (data) => {
    let Dat = new Date(startDate);
    var datum = Dat.toISOString().split("T")[0];
    console.log(datum);
    const obj = { date: startDate };
    let full = { ...data, ...obj };
    //data.push(obj);
    console.log(data, full);
    //alert(JSON.stringify(data));
  };

  const moreDetail = watch("moreDetail");

  return (
    <>
      <div className=" text-center text-2xl md:text-3xl">
        <p className="mt-4 font-Rampart font-bold">Neue Aktivität</p>
      </div>

      <div className="container px-5 py-12 mx-auto flex justify-center items-center">
        <form
          className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="grid sm:grid-cols-2 sm:gap-4 grid-cols-1">
            {/* //Title */}

            <div className="mb-2">
              <label
                className="block text-gray-700 text-sm font-bold "
                htmlFor="title"
              >
                Title
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder=""
                {...register("title", { required: true })}
              />
              {errors.title && (
                <span className="text-red-500">This field is required</span>
              )}
            </div>
            {/* --------------------------------------------------------------- */}
            {/* //Treffpunkt */}
            <div className="mb-2">
              <label
                className="block text-gray-700 text-sm font-bold "
                htmlFor="place"
              >
                Treffpunkt
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder=""
                {...register("place", { required: true })}
              />
            </div>
            {/* --------------------------------------------------------------- */}

            {/* Datum */}

            <div className="mb-2">
              <label
                className="block text-gray-700 text-sm font-bold "
                htmlFor="date"
              >
                Datum
              </label>
              <DatePicker
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                dateFormat="dd/MMM/yyyy"
              ></DatePicker>
            </div>

            {/* --------------------------------------------------------------- */}
            {/* Zeit */}
            <div className="mb-2">
              <label
                className="block text-gray-700 text-sm font-bold "
                htmlFor="time"
              >
                Zeit
              </label>
              <TimePicker
                className="shadow appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="time"
                onChange={onChange}
                value={value}
              ></TimePicker>
            </div>
            {/* --------------------------------------------------------------- */}

            {/* //Contact */}
            <div className="mb-2">
              <label
                className="block text-gray-700 text-sm font-bold "
                htmlFor="contact"
              >
                Kontakt
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder=""
                {...register("contact", { required: true })}
              />
            </div>
            {/* --------------------------------------------------------------- */}

            {/* //Phone */}
            <div className="mb-2">
              <label
                className="block text-gray-700 text-sm font-bold "
                htmlFor="phone"
              >
                Telephon
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder=""
                {...register("phone", { required: true })}
              />
            </div>
          </div>
          {/* --------------------------------------------------------------- */}
          {/* //Description */}
          <div className="mb-2">
            <label
              className="block text-gray-700 text-sm font-bold "
              htmlFor="description"
            >
              Beschreibung
            </label>

            <textarea
              className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
              type="text"
              {...register("description", { required: true, maxLength: 100 })}
            ></textarea>
          </div>
          {/* --------------------------------------------------------------- */}
          {/* //Anmeldung */}
          <div className="block">
            <div className="mt-2">
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  className="w-6 h-6 rounded"
                  {...register("register")}
                />
                <span className="ml-2">Anmeldung erfordelich </span>
              </label>
            </div>
          </div>

          {/* --------------------------------------------------------------- */}

          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold mt-4 py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Submit
          </button>
        </form>
      </div>
    </>
  );
};

export default NewActivity;

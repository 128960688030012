import { useState } from "react";
import Swal from "sweetalert2";
import { Routes, Route, useNavigate } from "react-router-dom";
import { store } from "./Globals";

const Login = () => {
  //PW Input string
  const [pwinput, setPwInput] = useState("");
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = store.useState("admin");

  //Helper Function
  function padTo2Digits(num) {
    return String(num).padStart(2, "0");
  }

  //Check the input data
  const handleLogin = (event, someParameter) => {
    const now = new Date();
    const current =
      padTo2Digits(now.getHours()) + padTo2Digits(now.getMinutes());
    if (parseInt(current) === parseInt(pwinput)) {
      console.log(parseInt(current), parseInt(pwinput));
      setIsAdmin(true);
      navigate("/");
    } else {
      setIsAdmin(false);
      console.log("admin false");
      Swal.fire({
        title: "Error!",
        text: "falsches Passwort",
        icon: "error",
        confirmButtonText: "Cool",
      });
    }
  };

  return (
    <div className=" bg-zinc-700 opacity-95 fixed inset-0 z-50">
      <div className="flex justify-center items-center h-screen">
        <div className="flex flex-col max-w-md p-6  before:after:rounded-md sm:p-10 bg-gray-50 text-gray-800">
          <div className="mb-8 text-center">
            <h1 className="my-3 text-4xl font-bold">Anmelden</h1>
            <p className="text-sm text-gray-600">
              Anmelden für Administratoren Rechte
            </p>
          </div>
          <form
            noValidate=""
            action=""
            className="space-y-12 ng-untouched ng-pristine ng-valid"
          >
            <div className="space-y-4">
              <div>
                <label htmlFor="email" className="block mb-2 text-sm">
                  Email address
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="info@meine_mail.ch"
                  className="w-full px-3 py-2 border rounded-md border-gray-300 bg-gray-50 text-gray-800"
                />
              </div>
              <div>
                <div className="flex justify-between mb-2">
                  <label htmlFor="password" className="text-sm">
                    Password
                  </label>
                  <a
                    rel="noopener noreferrer"
                    href="#"
                    className="text-xs hover:underline text-gray-600"
                  >
                    Forgot password?
                  </a>
                </div>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="*****"
                  className="w-full px-3 py-2 border rounded-md border-gray-300 bg-gray-50 text-gray-800"
                  value={pwinput}
                  onInput={(e) => setPwInput(e.target.value)}
                />
              </div>
            </div>
            <div className="space-y-2">
              <div className="flex flex-row space-x-3">
                <button
                  type="button"
                  className="w-full px-8 py-3 font-semibold rounded-md bg-blue-600 text-gray-50"
                  onClick={(e) => handleLogin(e, "text")}
                >
                  Anmelden
                </button>
                <button
                  type="button"
                  className="w-full px-8 py-3 font-semibold rounded-md bg-gray-400 text-gray-50"
                  onClick={() => navigate("/")}
                >
                  Cancel
                </button>
              </div>
              <p className="px-6 text-sm text-center text-gray-600">
                Don't have an account yet?
                <a
                  rel="noopener noreferrer"
                  href="#"
                  className="hover:underline text-blue-600"
                >
                  Sign up
                </a>
                .
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;

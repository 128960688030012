import axios from "axios";

export default axios.create({
  //baseURL: "http://localhost:3000/data",
  baseURL: "https://www.sm.nube-kosmetik.ch/data",

  headers: {
    "Content-type": "application/json",
  },
});

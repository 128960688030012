import React from "react";

const Page01 = () => {
  return (
    <>
      <div className="flex flex-row m-2 bg-slate-100 ">
        <div className="basis-2/3 bg-slate-100 text-center text-2xl md:text-3xl">
          <p className="mt-4 font-Rampart font-bold">Senioren Verein Forch</p>
          <p className="mt-4 text-lg">Teile Deine Interessen mit uns</p>
        </div>
        <div className="flex basis-1/3 bg-slate-400">
          <img
            src={require("../images/s2.jpg")}
            alt="..."
            className="shadow rounded h-auto align-middle border-none"
          />
        </div>
      </div>
      <p className="text-center mt-8 text-2xl">Wer sind wir?</p>
      <p className="w-4/5 text-left mx-auto mt-8">
        Lorem ipsum dolor sit, amet consectetur adipisicing elit. At impedit
        consequuntur cumque excepturi saepe. Esse delectus eligendi, ratione
        culpa harum natus illo nesciunt quisquam veritatis distinctio
        necessitatibus, voluptate impedit id! Quo natus, cum voluptatem
        necessitatibus, illum earum quas inventore, aliquid dolores repellendus
        tempore! Nesciunt in nulla ab repellat cupiditate ad saepe aut libero,
        rerum nobis praesentium aperiam quisquam pariatur necessitatibus nemo
        cumque porro eum deserunt?
      </p>
    </>
  );
};

export default Page01;

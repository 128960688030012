import React, { useMemo, useState, useEffect } from "react";
import Axios from "axios";

const ClientList = () => {
  const columns = useMemo(
    () => [
      { id: 0, Header: "Name", connector: "lastname" },
      { id: 1, Header: "Email" },
      { id: 2, Header: "Phone" },
      { id: 3, Header: "Actions" },
    ],
    []
  );

  const [clients, setClients] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await Axios.get("https://dummyjson.com/users/");
        setClients(response.data.users);
        console.log("->", response.data.users);
      } catch (err) {
        if (err.response) {
          //Not in 200 response range
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
        } else {
          console.log(`Error: ${err.message}`);
        }
      }
    };
    fetchPosts();
  }, []);
  return (
    <>
      {clients.length === 0 ? (
        "Loading"
      ) : (
        <div className="flex items-center sm:justify-center ml-4 sm:ml-0">
          <table className="text-sm border-separate border-spacing-y-2">
            <thead className="">
              <tr className="tr-class">
                <th className="th-class">Name</th>
                <th className="th-class">Email</th>
                <th className="th-class">Phone</th>
                <th className="th-class">Action</th>
              </tr>
            </thead>
            <tbody>
              {clients.map((client) => (
                <>
                  <tr className="tr-class">
                    <td className="td-class">{client.lastName}</td>
                    <td className="td-class">{client.email}</td>
                    <td className="td-class">{client.phone}</td>
                    <td className="td-class">
                      <span className="float-right rounded-md bg-green-600/50 px-4 py-px text-xs font-semibold uppercase text-green-900 antialiased">
                        Active
                      </span>
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default ClientList;

function isInThePast(date) {
    const today = new Date();

    // 👇️ OPTIONAL!
    // This line sets the hour of the current date to midnight
    // so the comparison only returns `true` if the passed in date
    // is at least yesterday
    today.setHours(0, 0, 0, 0);

    return date < today;
}


function DateSorter(obj) {

    //Add a new sorter Property
    obj.events.forEach(element => {
        let split = element.date.split(".");
        let date = split[2] + "." + split[1] + "." + split[0];
        element.sorter = new Date(date);
        element.outofdate = isInThePast(element.sorter);
    });
    //console.log("---------", obj);
    //Sort now
    obj.events.sort((a, b) => a.sorter - b.sorter);
    //console.log(obj.events.map(o => o.contact));
}

export default DateSorter;
import Navigation from './components/Navigation';
import { useState, useEffect, useLayoutEffect } from 'react';
import Navigation01 from './components/Navigation01';
import { NavbarPage } from './components/NavbarPage';
import Navigation02 from './components/Navigation02';
import Navigation03 from './components/Navigation03';
import Page01 from './components/Page01';
import Page02 from './components/Page02';
import Page03 from './components/Page03';
import Page04 from './components/Page04';
import api from './api/api'
import DateSorter from './AppHelper'
import { Routes, Route, Outlet, Link } from "react-router-dom";
import Login from "./components/Login";
import ClientList from './components/ClientList';
import Error from "./components/Error";
import LogOff from './components/LogOff';
import NewActivity from './components/NewActivity';


function App() {


  const [activities, setActivities] = useState([]);



  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await api.get('events.json');
        DateSorter(response.data);
        setActivities(response.data);

      } catch (err) {
        if (err.response) {
          //Not in 200 response range
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
        } else {
          console.log(`Error: ${err.message}`)
        }

      }
    }
    fetchPosts();
  }, [])

  const deleteActivity = () => {
  }

  // const doSwitch = () => {
  //   switch (page) {
  //     case 0:
  //       return <Page01 />;
  //     case 1:
  //       return <Page02 activities={activities}></Page02>
  //     case 2:
  //       return <Page03 />;
  //     default:
  //       return "No match";
  //   }
  // };

  return (
    <>

      <Navigation01></Navigation01>


      <Routes>
        <Route path="/" element={<Page01 />}></Route>
        <Route path="activities" element={<Page02 activities={activities} />}></Route>
        <Route path="contact" element={<Page03 />}></Route>
        <Route path="galery" element={<Page04 />}></Route>
        <Route path="admin" element={<Login />}></Route>
        <Route path="clientlist" element={<ClientList />}></Route>
        <Route path="newactivity" element={<NewActivity />}></Route>
        <Route path="logoff" element={<LogOff />}></Route>
        <Route path="*" element={<Error />}></Route>
      </Routes>
    </>
  );
}

export default App;

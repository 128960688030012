/* This example requires Tailwind CSS v2.0+ */

// https://tailwindui.com/#components   byRR

import { Fragment, useState, useEffect } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { BellIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import { store } from "./Globals";

const navigation = [
  { name: "Home", href: "/", current: true },
  { name: "Aktivitäten", href: "/activities", current: false },
  { name: "Galerie", href: "/galery", current: false },
  { name: "Kontakt", href: "/contact", current: false },
];

const supermenu1 = [
  { id: 0, name: "Anmelden", href: "/admin", showIsAdmin: false },
  { id: 1, name: "Mitglieder Liste", href: "/clientList", showIsAdmin: true },
  { id: 2, name: "Neue Aktivität", href: "newactivity", showIsAdmin: true },
  { id: 3, name: "Abmelden", href: "/logoff", showIsAdmin: true },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navigation01() {
  const [navi, setNavi] = useState(navigation);
  const [isAdmin, setIsAdmin] = store.useState("admin");
  const [dummy, setDummy] = useState(0);

  function handleClick(name) {
    let newnav = navi.filter(function (el) {
      name === el.name ? (el.current = true) : (el.current = false);
      return el;
    });
    setNavi(newnav);
  }

  function handleCurrent(name) {
    console.log(name);
    let count = dummy;
    navigation.forEach((target) => {
      {
        count = count + 1;
        {
          if (target.name === name) {
            target.current = true;
            setDummy(count);
          } else {
            target.current = false;
          }
        }
      }
      console.log(target.current);
    });
  }

  return (
    <Disclosure as="nav" className="bg-gray-800 sticky top-0 z-50">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 ">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex-shrink-0 flex items-center">
                  <img
                    className="block lg:hidden h-8 w-auto"
                    src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg"
                    alt="Workflow"
                  />
                  <img
                    className="hidden lg:block h-8 w-auto"
                    src="https://tailwindui.com/img/logos/workflow-logo-indigo-500-mark-white-text.svg"
                    alt="Workflow"
                  />
                </div>
                {/* //Big Screen Menu */}
                <div className="hidden sm:block sm:ml-6">
                  <div className="flex space-x-4">
                    {navi.map((item) => (
                      <>
                        <Link
                          onClick={() => handleCurrent(item.name)}
                          className={classNames(
                            item.current
                              ? "bg-gray-900 text-white"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                            "rounded-md px-3 py-2 text-sm font-medium"
                          )}
                          to={item.href}
                        >
                          {item.name}
                        </Link>
                      </>
                    ))}
                  </div>
                </div>
              </div>

              {/* //Bell Icon */}
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <button
                  type="button"
                  className="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                {/* Profile Avatar dropdown */}
                <Menu as="div" className="ml-3 relative">
                  <div>
                    <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                      <span className="sr-only">Open user menu</span>

                      <img
                        className="h-8 w-8 rounded-full"
                        //src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"

                        src={require(isAdmin
                          ? "../images/ok.png"
                          : "../images/nok.png")}
                        alt="avatar"
                      ></img>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {supermenu1.map((item) => (
                        <>
                          {item.showIsAdmin === isAdmin ? (
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  key={item.id}
                                  className={classNames(
                                    active ? "bg-gray-300" : "",
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}
                                  to={item.href}
                                >
                                  {item.name}
                                </Link>

                                // <a
                                //   key={item.id}
                                //   href={item.href}
                                //   className={classNames(
                                //     active ? "bg-gray-300" : "",
                                //     "block px-4 py-2 text-sm text-gray-700"
                                //   )}
                                // >
                                //   {item.name}
                                // </a>
                              )}
                            </Menu.Item>
                          ) : (
                            ""
                          )}
                        </>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
          {/* //Small Menu */}
          <Disclosure.Panel className="sm:hidden">
            {({ close }) => (
              <div className="px-2 pt-2 pb-3 space-y-1 ">
                {navi.map((item) => (
                  <>
                    {!item.isAdmin || isAdmin ? (
                      <Disclosure.Button
                        key={item.name}
                        as="div"
                        className={classNames(
                          item.current
                            ? "bg-gray-900 text-white"
                            : "text-gray-300 hover:bg-gray-700 hover:text-white",
                          "block px-3 py-2 rounded-md text-base font-medium"
                        )}
                      >
                        <Link
                          onClick={() => handleClick(item.name)}
                          to={item.href}
                        >
                          {item.name}
                        </Link>
                      </Disclosure.Button>
                    ) : (
                      ""
                    )}
                  </>
                ))}
              </div>
            )}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

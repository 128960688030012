import React from "react";
import { useState } from "react";
import Modal from "./Modal";
import { store } from "./Globals";
import Swal from "sweetalert2";

const Activity = ({ activity }) => {
  //Display details Handler
  const displayDetails = (activity) => {
    const act = JSON.stringify(activity);
    // console.log(activity);
    //console.log(act);
    setModalOn(true);
  };

  const [modalOn, setModalOn] = useState(false);
  const [choice, setChoice] = useState(false);
  const [isAdmin, setIsAdmin] = store.useState("admin");

  const clickConfirm = () => {
    Swal.fire({
      title: "Bist Du sicher?",
      text: "Der Eintrag wird endgültig gelöscht",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ja, Löschen!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Gelöscht!", "Die Aktivität wurde gelöscht", "success");
      }
    });
  };

  var background = activity.outofdate ? "bg-gray-400" : "bg-blue-300";

  return (
    <>
      <div
        className={`${background} flex flex-row mt-8 mx-4  rounded-2xl bg border-2 border-cyan-700`}
      >
        <div className="basis-1/6  pl-3">{activity.date}</div>
        <div
          className="basis-2/3  font-bold  text-center mx-1 cursor-pointer "
          onClick={() => displayDetails({ activity })}
        >
          {activity.title}
        </div>
        <div className="basis-1/12 "> Status</div>
        <div className="basis-1/12 pl-1">
          {activity.status ? (
            // Status Icon
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-green-800"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M5 13l4 4L19 7"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-red-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          )}
        </div>

        {/* Trash Icon */}
        <div className="mt-1 mr-2 cursor-pointer">
          {isAdmin ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-5 h-5"
              onClick={() => clickConfirm({ activity })}
            >
              <path
                fillRule="evenodd"
                d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z"
                clipRule="evenodd"
              />
            </svg>
          ) : (
            ""
          )}
        </div>

        {/* Pen Icon */}
        <div className="mr-4 mt-1">
          {isAdmin ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-5 h-5"
            >
              <path d="M2.695 14.763l-1.262 3.154a.5.5 0 00.65.65l3.155-1.262a4 4 0 001.343-.885L17.5 5.5a2.121 2.121 0 00-3-3L3.58 13.42a4 4 0 00-.885 1.343z" />
            </svg>
          ) : (
            ""
          )}
        </div>
      </div>
      {modalOn && (
        <Modal
          setModalOn={setModalOn}
          setChoice={setChoice}
          activity={activity}
        />
      )}
    </>
  );
};

export default Activity;

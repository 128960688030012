const Modal = ({ setModalOn, setChoice, activity }) => {
  const handleOKClick = () => {
    setChoice(true);
    setModalOn(false);
  };
  const handleCancelClick = () => {
    setChoice(false);
    setModalOn(false);
  };

  var theborder = activity.status ? "border-sky-500" : "border-red-500";
  var register = activity.register ? "erforderlich" : "nein";

  return (
    <div className=" bg-zinc-200 opacity-95 fixed inset-0 z-50">
      <div className="flex h-screen justify-center items-center ">
        <div
          className={`flex-col justify-center  bg-white h-auto border-4  rounded-xl mx-8  max-w-sm ${theborder}`}
        >
          <div className="flex text-2xl font-bold text-zinc-600  underline mt-5 justify-center ">
            {activity.title}
          </div>
          <div className=" text-sm mt-8 space-y-2 ml-4">
            <div className="flex flex-row">
              <div className="basis-1/3 font-bold">Kontakt:</div>
              <div className="basis-2/3 text-left">{activity.contact}</div>
            </div>
            <div className="flex flex-row">
              <div className="basis-1/3 font-bold">Tel:</div>
              <div className="basis-2/3 text-left"> {activity.phone}</div>
            </div>
            <div className="flex flex-row">
              <div className="basis-1/3 font-bold">Treffpunkt:</div>
              <div className="basis-2/3 text-left">{activity.place}</div>
            </div>
            <div className="flex flex-row">
              <div className="basis-1/3 font-bold">Zeit:</div>
              <div className="basis-2/3 text-left"> {activity.time}</div>
            </div>
            <div className="flex flex-row">
              <div className="basis-1/3 font-bold">Anmeldung:</div>
              <div className="basis-2/3 text-left">{register}</div>
            </div>
          </div>

          <div className="mx-4 mt-12">{activity.body}</div>
          <div className="flex justify-center">
            <button
              onClick={handleCancelClick}
              className="my-4 inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
            >
              Schliessen
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;

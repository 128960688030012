import React from "react";
import Activity from "./Activity";
import { store } from "./Globals";

const Page02 = ({ activities }) => {
  return (
    <>
      <div className="flex flex-row m-2 bg-slate-100 ">
        <div className="basis-2/3 bg-slate-100 text-center text-2xl md:text-3xl">
          <p className="mt-4 font-Rampart font-bold">Senioren Verein Forch</p>
          <p className="mt-4 text-lg ">Unsere nächsten Aktivitäten</p>
        </div>
        <div className="flex basis-1/3 bg-slate-400">
          <img
            src={require("../images/s3.jpg")}
            alt="..."
            className="shadow rounded h-auto align-middle border-none"
          />
        </div>
      </div>
      <p className="w-4/5 text-left mx-auto mt-8 text-sm mb-8">
        Wir freuen uns wenn jeder Event gut besucht wird. Natürlich haben wir
        immer offene Ohren für Vorschläge, Anregungen und Mithilfe. Bitte
        kontaktiert diesen Plan jeweils kurz vor dem Datum. So können wir ggf.
        Änderungen einfliesssen lassen.
      </p>
      <div className="mx-auto space-y-4 w-5/6 ">
        {activities.events.map((activity) => (
          <>
            <div key={activity.id}></div>
            <Activity activity={activity}></Activity>
          </>
        ))}
      </div>
    </>
  );
};

export default Page02;

import React from "react";

const DisplayImage = ({ image }) => {
  return (
    <div className="border-2 border-gray-500 w-full p-2 rounded basis-1/4 lg:w-1/3 md:w-1/2 mx-4 mt-8 hover:scale-110 ease-in-out duration-300">
      <img src={image} alt="gallery"></img>
    </div>
  );
};

export default DisplayImage;

import React from "react";

const Page03 = () => {
  return (
    <>
      <div className="flex flex-row m-2 bg-slate-100 ">
        <div className="basis-2/3 bg-slate-100 text-center text-2xl md:text-3xl">
          <p className="mt-4 font-Rampart font-bold">Senioren Verein Forch</p>
          <p className="mt-4 text-lg">Kontaktiere uns</p>
        </div>
        <div className="flex basis-1/3 bg-slate-400">
          <img
            src={require("../images/s1.jpg")}
            alt="..."
            className="shadow rounded h-auto align-middle border-none"
          />
        </div>
      </div>
      <p className="text-center mt-8 text-2xl">
        Sende Deine Kontakdaten oder informiere uns über Neuigkeiten
      </p>

      {/* -------------------Body----------------- */}
      {/* https://www.maps.ie/create-google-map/   byRR */}
      <section className="text-gray-600 body-font relative mt-8">
        <div className="absolute inset-0 bg-gray-300">
          <div className="width: 100%">
            <div className="width: 100%">
              <iframe
                title="ok"
                width="100%"
                height="1000"
                frameBorder="0"
                scrolling="no"
                marginHeight="0"
                marginWidth="0"
                src="https://maps.google.com/maps?width=100%25&amp;height=1000&amp;hl=en&amp;q=Forch+(Senioren%20Verein)&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              >
                <a href="https://www.gps.ie/wearable-gps/">wearable gps</a>
              </iframe>
            </div>
          </div>
        </div>
        <div className="container px-5 py-24 mx-auto flex">
          <div className="lg:w-1/3 md:w-1/2 bg-white rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md">
            <h2 className="text-gray-900 text-lg mb-1 font-medium title-font">
              Kontaktformular
            </h2>
            <p className="leading-relaxed mb-5 text-gray-600">
              Kontaktformular Senioren Verein Forch
            </p>
            <div className="relative mb-4">
              <label htmlFor="name" className="leading-7 text-sm text-gray-600">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              ></input>
              <label
                htmlFor="phone"
                className="leading-7 text-sm text-gray-600"
              >
                Telefon
              </label>
              <input
                type="phone"
                id="phone"
                name="phone"
                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              ></input>
              <label
                htmlFor="email"
                className="leading-7 text-sm text-gray-600"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              ></input>
            </div>
            <div className="relative mb-4">
              <label
                htmlFor="message"
                className="leading-7 text-sm text-gray-600"
              >
                Mitteilung
              </label>
              <textarea
                id="message"
                name="message"
                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
              ></textarea>
            </div>
            <button className="text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">
              Send
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default Page03;

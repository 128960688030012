import React from "react";
import { store } from "./Globals";
import { useEffect } from "react";

const LogOff = () => {
  const [isAdmin, setIsAdmin] = store.useState("admin");

  useEffect(() => {
    setIsAdmin(false);
  }, []);

  console.log("Logout");
  return <div></div>;
};

export default LogOff;
